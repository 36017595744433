<template>
  <div class="wrapper">
    <div class="background">
      <div class="container">
        <h2>ÁREA DE EMPRENDEDORES</h2>
        <div class="contents">
          <p>
            Emprender con con el apoyo del Club de Ejecutivos, no es una forma
            más de poder comenzar a construir una empresa, es poder tener a
            tu disposición una experiencia acumulada de cientos de expertos
            del mundo empresarial que viven de primera mano la realidad de
            cada empresa y autónomo.
          </p>
          <p>
            Cada asociado te ayudará a visualizar el futuro de tu empresa y
            la toma de decisiones correctas para evitarte que no seas parte
            de ese 90% de emprendedores que mueren en los primeros años.
          </p>
          <p>
            Los asociados trabajan en paralelo con todas las áreas del
            emprendedor, como expertos te brinda la oportunidad de diseñar
            tu empresa con múltiples recursos humanos y técnicos que te
            apoyaran desde el inicio a poner en marcha tu reto personal
            y profesional.
          </p>
          <p>
            Nuestros centros de emprendimiento apoyados por nuestros expertos,
            te ofrecen programas de formación, asesoramiento y creatividad,
            para que logres tus objetivos.
          </p>
        </div>
        <router-link to="/emprendedores">
          <img loading="lazy" alt="icon" id="btn-saber-mas" src="/images/work/btn-saber-mas.png" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('empleabilidad.entrepreneurs_club'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.entrepreneurs_club') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.entrepreneurs_club') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .background {
    background: url('/images/work/emprendedores-bg.jpg') #19171c no-repeat top;
    background-size: cover;

    .container {
      padding: 50px 20px;

      h2 {
        color: white;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 3.5rem;
        text-align: left;
      }

      .contents {
        color: white;
        text-align: left;
        font-size: 0.9rem;
      }
    }
  }

  #btn-saber-mas {
    cursor: pointer;
  }

  #btn-saber-mas:hover {
    opacity: 0.7;
  }
  
  @media screen and (max-width: 767px) {
    #btn-saber-mas {
      width: 100%;
    }

    .container {
      h2 {
        font-size: 2rem !important;
        line-height: 2.5rem !important;
      }
    }
  }
</style>

<style lang="scss">
//   .lang {
//     background: #19171c !important;

//     .idiomas {
//       color: white;
//     }
//   }
</style>